<template>
  <div>
    <ManageCategory />
  </div>
</template>
<script>
import ManageCategory from "@/components/Category/ManageCategory";
export default {
  components: {
    ManageCategory,
  },
  created() {
  },
};
</script>